import { createApp } from 'vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
import {createRouter, createWebHashHistory } from "vue-router";
import { createStore } from 'vuex';
import './registerServiceWorker';

import Blank from './Blank.vue';
import Base from './Base.vue';
import App from './App.vue';

import './styles/app.css'; //css tailwind
import './styles/fontawesome.min.css'; //css fontawesome
import './registerServiceWorker'
import './styles/app.css'; // Here

const store = createStore({
    namespaced: true,
    state () {
        return {
            authenticated: false
        }
    },
    mutations: {
        setAuthenticated(state, value) {
            state.authenticated = value;
        },
    },
    getters: {
        getAuthenticated(state) {
            return state.authenticated;
        }
    },
    actions: {
        getUser({ commit }) {
            return axios
                .get("https://api.vima.fesemi.org/user")
                .then(({ data }) => {
                    commit("setAuthenticated", true);
                    return data;
                })
                .catch(({ response: { data } }) => {
                    commit("setAuthenticated", false);
                    return data;
                });
        },
    }
});

const routes = [
    {
        path: "/",
        component: Base,
        children: [
            {
                path: "/",
                name: 'Login',
                component: () => import('./components/LoginPage.vue'),
                meta: {
                    reload: true,
                },
            },
            {
                path: "/versiones",
                name: "Versiones",
                component: () => import('./components/Versiones.vue'),
            },
            {
                path: '/home-lite',
                name: 'HomeLite',
                component: () =>
                    import('./components/HomeLite.vue'),
            },
            {
                path: '/escala-lite/:slug',
                name: 'EscalaLite',
                component: () =>
                    import('./components/EscalaLite.vue'),
            }
        ],
    },

    {
        path: "/app",
        component: App,
        children: [
            {
                path: '/home',
                name: 'Home',
                component: () =>
                    import('./components/Home.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/nuevo-paciente',
                name: 'Expediente',
                component: () =>
                    import('./components/Expediente.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/ver-perfil/:expediente',
                name: 'VerPerfil',
                component: () =>
                    import('./components/Expediente.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/escala/:slug/:codigo',
                name: 'Escala',
                component: () =>
                    import('./components/Escala.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/perfil',
                name: 'Perfil',
                component: () =>
                    import('./components/PerfilPage.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/avisos',
                name: 'Avisos',
                component: () =>
                    import('./components/Avisos.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/historial',
                name: 'Logs',
                component: () =>
                    import('./components/Logs.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/busqueda/:busqueda',
                name: 'Busqueda',
                component: () =>
                    import('./components/Busqueda.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/enviar/:expediente',
                name: 'EnviarExpediente',
                component: () =>
                    import('./components/EnviarExpediente.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/guia-usuario',
                name: 'GuiaUsuario',
                component: () =>
                    import('./components/GuiaUsuario.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/politica-privacidad',
                name: 'PoliticaPrivacidad',
                component: () =>
                    import('./components/PoliticaPrivacidad.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/images/:nombre',
                name: 'ImagesView',
                component: () =>
                    import('./components/Image.vue'),
                meta: {
                    requiresAuth: true,
                },
            }

        ],
    },

];


const router = createRouter({
    history: createWebHashHistory(),
    routes,
});


router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters["getAuthenticated"]) {
            next();
            return;
        }
        next('/');
    } else {
        if (store.getters["getAuthenticated"]) {
            next('/home');
            return;
        }
        next();
    }
});

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


store.dispatch("getUser").then(() => {
    createApp(Blank)
        .use(router)
        .use(VueAxios, axios)
        .use(store)
        .mount('#app')
});
