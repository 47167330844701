<template>
  <header class="fixed w-full flex justify-between items-center py-4 px-6 z-50 top-0 left-0 max-w-2xl mx-auto inset-x-0 bg-claro border-b-2 border-white box-webapp">
      <img class="h-[48px]" :src="vima_logo">

      <form class="search-block h-[48px] px-2 w-[calc(100%-166px)] items-center flex relative" @submit.prevent="buscar()">
          <div id="inputBlock" class="hidden w-full">
              <i class=".text-sm fa-solid fa-search absolute mt-[12px] left-[1.3rem]" @click="openSearch()"></i>
              <input type="search" v-model="busqueda" ref="search" class="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-200 rounded-lg bg-gray-200" placeholder="Buscar">
          </div>

          <i id="oSearch" class="text-lg fa-solid fa-search absolute right-[1.5rem] cursor-pointer" @click="openSearch()"></i>
          <i id="xmark" class="text-lg fa-solid fa-circle-xmark absolute right-[1rem] cursor-pointer" @click="closeSearch()" style="display: none"></i>
      </form>
      <router-link :to="{name: 'Avisos'}" class="notifications w-[40px] relative">


        <!--      <router-link :to="{name: 'Recientes'}"  @click="this.notifications = 0"  class="w-[34px] h-[34px] relative flex justify-center items-center text-center text-white rounded-lg focus:outline-none bg-gradient-to-r from-principal to-secondary">-->

        <div class="bell-logo h-[40px] w-[40px] text-center rounded-full bg-intermedio">
            <i class="text-lg fa-solid fa-bell text-white m-auto py-[10px]"></i>
        </div>

        <div v-if="notifications" class="bg-rojo absolute inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white  rounded-full -top-1 -end-1"></div>

<!--      </router-link>-->
      </router-link>

  </header>

  <div class="px-3 mt-[82px] pb-[85px] min-h-[calc(100vh-130px)] relative box-webapp pt-10">
    <router-view></router-view>
  </div>

  <footer class="fixed bottom-0 left-0 z-50 w-full bg-white max-w-2xl mx-auto inset-x-0 box-webapp">

    <div v-if="deferredPrompt && iosPrompt != 1 && !this.getCookie('dismissInstallApp')" class="border-0 bg-black px-6 pb-6 pt-8 flex relative z-50">

      <div @click="dismiss" class="w-[34px] h-[34px] flex justify-center items-center text-center text-white rounded-lg outline-none bg-rojo absolute right-[10px] -top-4">

        <i class="text-lg fa-solid fa-xmark"></i>

      </div>

      <div>
        <img class="w-[58px] h-auto rounded-lg" src="">
      </div>

    </div>

    <div class="flex justify-center h-full mx-auto font-medium bg-oscuro px-[20px] pb-4 border-t-2 border-white">
        <router-link :to="{name: 'Perfil'}" class="inline-flex flex-col items-center group w-1/5 mx-1" :class="{ active : this.$route.name == 'Perfil' }">

            <i class="mt-3 inline-block text-white group-[.active]:text-intermedio text-xl fa-solid fa-user"></i>

            <span class="text-[11px] text-white group-[.active]:text-intermedio" >Perfil</span>

        </router-link>
        <router-link :to="{name: 'Logs'}" class="inline-flex flex-col items-center group w-1/5 mx-1" :class="{ active : this.$route.name == 'Logs' }">

            <i class="mt-3 inline-block text-white group-[.active]:text-intermedio text-xl fa-solid fa-clock"></i>

            <span class="text-[11px] text-white group-[.active]:text-intermedio" >Historial</span>

        </router-link>
        <div class="inline-flex flex-col items-center group w-1/5 mx-2 relative min-w-[75px]" :class="{ active : this.$route.name == 'Home' }" @click="this.$route.name == 'Home'?goToTop():''">
            <router-link :to="{name: 'Home'}">

                <div class="bell-logo text-center ">
                    <div class="h-[60px] w-[60px] bg-white group-[.active]:bg-claro border-oscuro rounded-full absolute bottom-0 m-auto left-0 right-0 shadow-[#29536A_0px_0px_30px_-10px]"></div>
                    <i class="inline-block text-midgray group-[.active]:text-oscuro text-xl fa-solid fa-house m-auto pt-2 relative z-20"></i>
                </div>

            </router-link>

        </div>
<!--        <router-link :to="{name: 'Home'}" class="inline-flex flex-col items-center group w-1/5 mx-1" :class="{ active : this.$route.name == 'Home' }">-->

<!--            <i class="mt-2 from-principal to-secondary inline-block text-lightgray group-[.active]:text-oscuro text-xl fa-solid fa-circle-info"></i>-->

<!--        </router-link>-->
        <router-link :to="{name: 'GuiaUsuario'}" class="inline-flex flex-col items-center group w-1/5 mx-1" :class="{ active : this.$route.name == 'GuiaUsuario' }">

            <i class="mt-3 inline-block text-white group-[.active]:text-intermedio text-xl fa-solid fa-circle-info"></i>

            <span class="text-[11px] text-white group-[.active]:text-intermedio" >Uso</span>

        </router-link>
        <router-link :to="{name: 'PoliticaPrivacidad'}" class="inline-flex flex-col items-center group w-1/5 mx-1" :class="{ active : this.$route.name == 'PoliticaPrivacidad' }">

            <i class="mt-3 inline-block text-white group-[.active]:text-intermedio text-xl fa-solid fa-lock"></i>

            <span class="text-[11px] text-white group-[.active]:text-intermedio">Privacidad</span>
        </router-link>

    </div>
  </footer>


  <button id="to-top-button" @click="goToTop()"
          class="hidden fixed z-40 bottom-[92px] right-2 flex justify-center items-center border-0 w-12 h-12 rounded-lg shadow-md bg-black text-white text-lg font-semibold transition-colors duration-300 opacity-20">

    <i class="text-sm fa-solid fa-chevron-up"></i>

  </button>

</template>

<script>
    import vima_logo  from './assets/vima_logo.png'

    export default {
    name: 'AppVue',

    data() {
        return {
            vima_logo,
            notifications: 0,
            deferredPrompt: null,
            iosPrompt: null,
            busqueda: ""
        }
    },
    mounted() {
        //window.addEventListener('scroll', this.handleScroll);
        this.createCSRF();
        this.getNotificaciones('avisos');
    },
    methods: {
        getNotificaciones(estado){
            this.axios.get('https://api.vima.fesemi.org/api/expedientes?e='+estado)
                .then(response => {
                    this.notifications = response.data.length;
                })
                .catch(error => console.log(error));
        },
        createCSRF(){
            this.axios.get('https://api.vima.fesemi.org/sanctum/csrf-cookie');
        },
        handleScroll(){
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                document.getElementById("to-top-button").classList.remove("hidden");
            } else {
                document.getElementById("to-top-button").classList.add("hidden");
            }
        },
        goToTop(){
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        openSearch(){
            document.getElementById("inputBlock").classList.remove("hidden");
            document.getElementById("xmark").style.display = "block";
            document.getElementById("oSearch").style.display = "none";
        },
        closeSearch(){
            document.getElementById("inputBlock").classList.add("hidden");
            document.getElementById("xmark").style.display = "none";
            document.getElementById("oSearch").style.display = "block";
            this.busqueda = "";
        },
        buscar(){
            this.$router.push("/busqueda/"+this.busqueda);
            this.closeSearch();
        }
    }

  }

</script>