<template>
    <div class="max-w-2xl mx-auto bg-white box-webapp">
        <router-view />
        <div v-if="deferredPrompt && iosPrompt != 1 && !this.getCookie('dismissInstallApp')" class="border-0 bg-black px-6 pb-6 pt-8 flex fixed bottom-0 w-full max-w-2xl z-50">

            <div @click="dismiss" class="w-[34px] h-[34px] flex justify-center items-center text-center text-white rounded-lg outline-none bg-rojo absolute right-[10px] -top-4">

                <i class="text-lg fa-solid fa-xmark"></i>

            </div>

            <div>
                        <img class="w-[58px] h-auto rounded-lg" :src="vima_semi_msg">
            </div>

            <div class="pl-4 -mt-1 ">
                <div class="text-[17px] text-white font-bold">
                    INSTALA NUESTRA APLICACIÓN
                </div>

                <div class="font-medium text-[13px] text-white leading-4">
                    Y accede de manera rápida a la APP.<br>
                    ¡No ocupa espacio en tu dispositivo!
                </div>

                <button @click="install" class="outline-none rounded-lg mt-2 w-fit py-2 px-5 text-center bg-intenso text-white text-[14px] font-bold">
                    INSTALAR
                </button>
            </div>

        </div>
        <div  v-if="iosPrompt == 1 && !this.getCookie('dismissInstallApp')" class="z-50 fixed top-0 left-0 w-screen h-screen bg-black/90 text-white p-6 flex justify-center">

            <div @click="dismiss" class="absolute top-4 right-4" >Continuar en el navegador</div>

            <div class="text-center leading-10 mt-36">
                        <img class="w-[100px] h-auto rounded-lg m-auto mb-6" :src="vima_semi_msg">

                <div class="text-[20px]">
                    <span>Para instalar nuestra app</span>
                    <br>
                              <span>pulsa en <img :src="box_arrow_up" class="-mt-1 inline-block w-6 invert"></span>
                    <br>
                    <span> y elige la opción<br>"Añadir a pantalla de inicio"</span>
                </div>
            </div>

            <i class="text-[50px] fa-solid fa-arrow-down font-sm fixed bottom-4"></i>

        </div>
    </div>
</template>
<script>
    import update from './mixins/update'
    import vima_semi_msg  from './assets/vima_semi_msg.png'
    import box_arrow_up  from './assets/box-arrow-up.png'

    export default {
        name: 'BlankLayout',
        data() {
            return {
                vima_semi_msg,box_arrow_up,
                deferredPrompt: null,
                iosPrompt: null
            }
        },
        mixins: [update],
        created(){
            window.addEventListener("beforeinstallprompt", e => {
                e.preventDefault();
                // Stash the event so it can be triggered later.
                this.deferredPrompt = e;
            });
            window.addEventListener("appinstalled", () => {
                this.deferredPrompt = null;
            });

            // Detects if device is on iOS
            const isIos = () => {
                const userAgent = window.navigator.userAgent.toLowerCase();
                return /iphone|ipad|ipod/.test( userAgent );
            }
            // Detects if device is in standalone mode
            const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

            // Checks if should display install popup notification:
            if (isIos() && !isInStandaloneMode()) {
                this.deferredPrompt = 1;
            }

        },
        methods: {
            async dismiss() {
                this.deferredPrompt = null;
                this.iosPrompt = null;
                this.setCookie('dismissInstallApp', 1, 1 );
            },
            async install() {
                if(this.deferredPrompt == 1){
                    this.iosPrompt = 1;
                }else{
                    this.deferredPrompt.prompt();
                }
            },
            setCookie(cname, cvalue, exdays) {
                const d = new Date();
                d.setTime(d.getTime() + (exdays*24*60*60*1000));
                let expires = "expires="+ d.toUTCString();
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            },
            getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for(let i = 0; i <ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }
        }
    }
</script>